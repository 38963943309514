import Vue from 'vue';
import { Module, VuexModule, Action } from 'vuex-module-decorators'
import { User } from './auth';
import { Auth } from './modules';

export type TeacherDetails = {
  youtube?: string;
  instagram?: string;
  facebook?: string;
  twitter?: string;
}

export interface Teacher {
  id: string;
  name: string;
  businessName?: string;
  about?: string;
  details: TeacherDetails;
  classes: number;

  userId: string;
  user?: User;

  ratingsSum: number;
  ratingsCount: number;
  avgRating: number;

  active: boolean;

  // payments
  wiseRecipientId?: string;

  disabledAt?: Date;
  deletedAt?: Date;

  updatedAt: Date;
  createdAt: Date;
}

@Module({ namespaced: true, name: 'teachers', preserveState: true  })
class Teachers extends VuexModule {
  @Action({ rawError: true })
  async listTeachers(data: { limit?: number; offset?: number } = {}) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    const response = await Vue.$axios.get(`/teachers?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async getTeacher(data: { id: string }) {
    const response = await Vue.$axios.get(`/teachers/${data.id}`);
    return response.data;
  }

  @Action({ rawError: true })
  async updateTeacher(data: Partial<Teacher>) {
    const id = (Auth.user as User).teacherId;
    const response = await Vue.$axios.patch(`/teachers/${id}`, data);
    await Auth.getSelfInformation();
    return response.data;
  }

  @Action({ rawError: true })
  async getPayoutStatus(): Promise<{
    availablePayout?: number;
    comission?: number;
  }> {
    const id = (Auth.user as User).teacherId;
    const response = await Vue.$axios.get(`/teachers/${id}/payout-status`);
    return response.data;
  }

  @Action({ rawError: true })
  async getPayoutInfo(): Promise<{
    payoutMethod?: string;
    accountName?: string;
    accountEmailId?: string;
  }> {
    const response = await Vue.$axios.get(`/payout-info`);
    return response.data;
  }

  @Action({ rawError: true })
  async getStatistics(data: { id: string }): Promise<{
    totalClasses: number;
    totalStudents: number;
  }> {
    const response = await Vue.$axios.get(`/teachers/${data.id}/statistics`);
    return response.data;
  }
}

export default Teachers;
