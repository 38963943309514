import Vue from 'vue';
import { Module, VuexModule, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'rates', preserveState: true  })
class Rates extends VuexModule {

  @Action({ rawError: true })
  async listClassRates(data: { id?: string; limit?: number; offset?: number } = {}) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.id) {
      query.append('classId', `${data.id}`);
    }

    const response = await Vue.$axios.get(`/rates?${query.toString()}`);
    return response.data;
  }
  
  @Action({ rawError: true })
  async addClassRate(data: { id: string; payload: any}) {
    const response = await Vue.$axios.post(`/classes/${data.id}/rate`, data.payload);
    return response.data;
  }

  @Action({ rawError: true })
  async listTeacherRates(data: { id?: string; limit?: number; offset?: number } = {}) {
    const query = new URLSearchParams();

    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    if (data.id) {
      query.append('teacherId', `${data.id}`);
    }

    const response = await Vue.$axios.get(`/rates?${query.toString()}`);
    return response.data;
  }

  @Action({ rawError: true })
  async addTeacherRate(data: { id: string; payload: any}) {
    const response = await Vue.$axios.post(`/teachers/${data.id}/rate`, data.payload);
    return response.data;
  }

  @Action({ rawError: true })
  async addRate(data: { payload: any }) {
    const response = await Vue.$axios.post(`/rates`, data.payload);
    return response.data;
  }

  @Action({ rawError: true })
  async getRates(data: { classId?: string; teacherId?: string; userId?: string; limit?: number; offset?: number } = {}) {
    const query = new URLSearchParams();

    if (data.classId) {
      query.append('classId', `${data.classId}`);
    }

    if (data.teacherId) {
      query.append('teacherId', `${data.teacherId}`);
    }

    if (data.userId) {
      query.append('userId', `${data.userId}`);
    }
    
    if (data.limit) {
      query.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      query.append('offset', `${data.offset}`);
    }

    const response = await Vue.$axios.get(`/rates?${query.toString()}`);
    return response.data.items;
  }

}

export default Rates;
