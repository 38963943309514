import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

import auth from './auth';
import teachers from './teachers';
import rates from './rates';
import chat from './chat';
import classes from './classes';
import blog from './blog';

const modules = {
  auth,
  teachers,
  rates,
  chat,
  classes,
  blog
};

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    ...Object.values(modules).map(mod => mod.state),
  },
  modules,
  plugins: [vuexLocal.plugin],
});
