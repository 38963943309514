
import { Vue, Component, Watch } from 'vue-property-decorator';

import { isEmpty } from 'ramda';

import { Auth, Chat } from '@/store/modules';

import Default from '@/layouts/Default.vue';
import centered from '@/layouts/Centered.vue';
import AppTutorialModal from '@/components/AppTutorialModal.vue'
import { User } from './store/auth';

@Component({ components: { AppTutorialModal } })
export default class extends Vue {
  layouts: any = {
    Default,
    centered
  }
  meetingInterval: any = null;
  meetingCounter = '';
  meetingText = '';
  searchInput = '';
  refreshChatInterval = null as any;
  showInAppTutorial = false;

  get unreadChats() {
    return Chat.unreadChats;
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get user() {
    return Auth.user as User;
  }

  logout() {
    return Auth.openLogoutPage();
  }

  get layout() {
    return this.$route.meta && this.$route.meta.layout;
  }

  get layoutComponent() {
    if (this.layout) {
      return this.layouts[this.layout];
    }

    return this.layouts.Default;
  }

  get isTeacher() {
    return this.user.role === 'teacher' || this.user.role === 'admin';
  }

  get finishedTeacherOnboarding() {
    return Auth.finishedTeacherOnboarding
  }

  get isOnMeetingPage() {
    return this.$route.name === 'meeting'
  }


  get userHasNotPaidClasses() {
    return this.loggedIn
      && !isEmpty(this.user)
      && this.user.hasPaidClasses !== undefined
      && !this.user.hasPaidClasses
  }

  mounted() {
    clearInterval(this.refreshChatInterval);
    if (this.loggedIn) {
      this.refreshChatInterval = setInterval(() => {
        if (!this.loggedIn) {
          clearInterval(this.refreshChatInterval);
          return;
        }

        Chat.getUnreadChats();
      }, 15000)

      this.handleAppTutorialModal()
    }
  }

  async handleAppTutorialModal() {
    if (this.userHasNotPaidClasses && !!localStorage.getItem('loggedIn')) {
      const appTutorialViews = this.user.details.tutorialFinishedCounter || 0
      if (!appTutorialViews || appTutorialViews < 3) {
        this.showInAppTutorial = true
        await Auth.updateAccount({
          details: {
            tutorialFinishedCounter: appTutorialViews + 1
          }
        });
      }
    }
  }

  search() {
    this.$router.push({ name: 'search', query: { query: this.searchInput } });
  }

  refreshMeetingEndDate() {
    const date = Auth.meetingEndDate;

    if (!date) {
      this.meetingCounter = '';
      this.meetingText = '';
      return;
    }

    const timeLeft = new Date(date).valueOf() - Date.now();

    if (timeLeft < 0) {
      this.meetingText = 'Class ends now';
      this.meetingCounter = '';
      return;
    }

    const secondsLeft = Math.round(timeLeft / 1000)
    const minutesLeft = Math.floor(secondsLeft / 60);

    const minutes = minutesLeft;
    const seconds = (secondsLeft - minutesLeft * 60) < 10 ? `0${secondsLeft - minutesLeft * 60}` : secondsLeft - minutesLeft * 60;

    this.meetingText = `Class ends in `;
    this.meetingCounter = `${minutes}:${seconds} `;
  }

  @Watch('$route.name')
  onRouteChanged() {
    if (this.isOnMeetingPage) {
      this.meetingInterval = setInterval(() => {
        this.refreshMeetingEndDate();
      }, 100);
    } else {
      if (this.meetingInterval) {
        clearInterval(this.meetingInterval);
      }
    }
  }
}
