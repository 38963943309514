
import { setOptions } from 'vue-gtag';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Cookies',
})
export default class extends Vue {
  modal = false;
  displayCookie = false;
  AnalyticsCookies = true;
  FunctionalityCookies = true;
  TargetingCookies = false;

  mounted() {
    if (localStorage.getItem('cookiesDialogClosed') === 'true') {
      this.displayCookie = false;
    } else {
      this.displayCookie = true;
    }
  }

  save() {
    if (this.AnalyticsCookies) {
      localStorage.setItem('analytics', 'true');
      setOptions({
        config: { id: "AW-399680930" }
      });
    } else {
      localStorage.setItem('analytics', 'false');
    }

    localStorage.setItem('cookiesDialogClosed', 'true');
    this.displayCookie = false;
  }
}
