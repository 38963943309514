
import { Vue, Component, Prop, Watch } from "vue-property-decorator"

interface SlideI {
	text: string;
	img: string;
	imgSm: string;
}

@Component
export default class extends Vue {
	@Prop({ default: false }) show!: boolean
	model = 0
	interval: ReturnType<
		typeof setTimeout
	> = (undefined as unknown) as ReturnType<typeof setTimeout>

	slides: SlideI[] = [
		{
			text: "Browse & Explore classes of your interest",
			img: "/tutorial-1.jpg",
			imgSm: "/tutorial-1-sm.jpg",
		},
		{
			text: "Choose a Class, Pay & Reserve your Seat.",
			img: "/tutorial-2a.jpg",
			imgSm: "/tutorial-2a-sm.jpg",
		},
		{
			text: "Join your Class & Start Learning.",
			img: "/tutorial-3.jpg",
			imgSm: "/tutorial-3-sm.jpg",
		},
	]

	slide2Img = this.isMobile() ? this.slides[1].imgSm : this.slides[1].img

	@Watch("model")
	modelChanged(curr: number, prev: number) {
		if (curr === 1) {
			this.interval = setInterval(() => {
				const actualSrcImg = this.isMobile()
					? this.slides[1].imgSm
					: this.slides[1].img
				if (this.slide2Img === actualSrcImg) {
					this.slide2Img = this.isMobile() ? "/tutorial-2b-sm.jpg" : "/tutorial-2b.jpg"
				} else {
					this.slide2Img = actualSrcImg
				}
			}, 3000)
		} else if (curr !== 1 && prev === 1) {
			clearInterval(this.interval)
		}
	}

	hide() {
		this.$emit("hide")
	}

	isMobile(): boolean {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return true
		} else {
			return false
		}
	}

	getImgSrc(slide: SlideI): string {
		let src = ''
    if (this.model !== 1) {
      src = this.isMobile() ? slide.imgSm: slide.img
    } else {
			src = this.slide2Img
		}

		return require(`@/assets${src}`)
	}
}
