import { clone } from 'ramda';
import Vue from 'vue';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Auth } from './modules';

type ChatMember = {
  type: 'teacher' | 'student';
  name: string;
  id: string;
  avatarUrl?: string;
}

const getChatNameAndAvatar = (chat: any) => {
  const userId = (Auth.user as any).id;
  const teacherId = (Auth.user as any).teacherId;

  const otherUser = chat.chatUsers.find((chatUser: any) => {
    if (teacherId && chatUser.teacherId !== teacherId && chatUser.userId !== userId) {
      return chatUser;
    }

    if (!teacherId && chatUser.userId !== userId) {
      return chatUser;
    }
  });

  const otherUserData = otherUser.user || otherUser.teacher;

  return {
    ...chat,
    name: otherUserData?.name || 'Unknown User',
    avatarUrl: otherUserData?.avatarUrl,
    hasUnreads: chat.lastReadMessageDate ? new Date(chat.lastReadMessageDate).valueOf() < new Date(chat.lastMessageDate).valueOf() : true,
  };
};

@Module({ namespaced: true, name: 'chat', preserveState: true  })
class Chat extends VuexModule {
  chats: any[] = [];
  chatsCount = 0;
  chatsPage = 0;
  unreadChats = 0;
  currentChatId: string = '';
  currentChatMembers: ChatMember[] = [];
  currentChatRole: 'teacher' | 'student' = 'student';
  loadingChats = false;

  @Mutation
  setCurrentChatId(id: string) {
    this.currentChatId = id;
  }

  @Mutation
  setCurrentChatMembers(members: ChatMember[]) {
    this.currentChatMembers = clone(members);
  }

  @Mutation
  setCurrentChatRole(role: 'teacher' | 'student') {
    this.currentChatRole = role;
  }

  @Mutation
  setChats (chats: any[]) {
    this.chats = clone(chats);
  }

  @Mutation
  setUnreadChats (num: number) {
    this.unreadChats = num;
  }

  @Mutation
  setChatsCount (count: number) {
    this.chatsCount = count;
  }

  @Mutation
  setChatsPage (page: number) {
    this.chatsPage = page;
  }

  @Mutation
  setLoadingChats (loading: boolean) {
    this.loadingChats = loading;
  }

  @Mutation
  markChatAsRead (chatId: string) {
    const index = this.chats.findIndex((chat: any) => chat.id === chatId);

    if (index === -1) {
      return;
    }

    const previousValue = this.chats[index].hasUnreads;
    this.chats[index].hasUnreads = false;

    if (this.unreadChats > 0 && previousValue === true) {
      this.unreadChats -= 1;
    }
  }

  @Action({ rawError: true })
  async getChats(data: { limit?: number; offset?: number }) {
    const searchParams = new URLSearchParams();

    if (data.limit) {
      searchParams.append('limit', `${data.limit}`);
    }

    if (data.offset) {
      searchParams.append('offset', `${data.offset}`);
    }

    const response = await Vue.$axios.get(`/chats?${searchParams.toString()}`);
    const chats = response.data.items;

    return {
      items: chats.map(getChatNameAndAvatar),
      count: response.data.count
    }
  }

  @Action({ rawError: true })
  async loadChats() {
    this.setLoadingChats(true);
    try {
      const { items, count } = await this.getChats({ limit: 6, offset: this.chatsPage * 6 });
  
      this.setChats(items);
      this.setChatsCount(count);
    } catch (err) {
      this.setChats([]);
      this.setChatsCount(0);
      this.setChatsPage(0);
      this.setUnreadChats(0);
    }
    this.setLoadingChats(false);
  }

  @Action({ rawError: true })
  async getUnreadChats () {
    try {
      const response = await Vue.$axios.get('/chats/unread');
      const { count } = response.data;
      
      if (this.unreadChats < Number(count)) {
        this.loadChats();
      }

      this.setUnreadChats(Number(count));
    } catch (err) {
      this.setUnreadChats(0);
    }
  }

  @Action({ rawError: true })
  async readCurrentChat() {
    await Vue.$axios.post('/messages/read', { chatId: this.currentChatId, teacher: this.currentChatRole === 'teacher' });
    this.markChatAsRead(this.currentChatId);
  }

  @Action({ rawError: true })
  async setCurrentChat(data: { chatId: string }) {
    const chat = (await Vue.$axios.get(`/chats/${data.chatId}`)).data;

    this.setCurrentChatId(chat.id);
    this.setCurrentChatMembers([]);
    const members: ChatMember[] = [];
    
    chat.chatUsers.forEach((chatUser: any) => {
      const toPush: ChatMember = {
        type: chatUser.teacherId ? 'teacher' : 'student',
        id: chatUser.teacherId || chatUser.userId,
        name: chatUser.user?.name || chatUser.teacher?.name,
        avatarUrl: chatUser.user?.avatarUrl || chatUser.teacher?.avatarUrl
      };

      members.push(toPush);
    });

    this.setCurrentChatMembers(members);

    const me = this.currentChatMembers.find(member => member.id === (Auth.user as any).id || member.id === (Auth.user as any).teacherId);
    const currentChatRole = me?.type || 'student';

    this.setCurrentChatRole(currentChatRole);

    try {
      await this.readCurrentChat();
    } catch (err: any) {
      console.warn(err.message);
    }
  }

  @Action({ rawError: true })
  getMessageAuthor(data: { senderId: string }) {
    const found = this.currentChatMembers.find(member => member.id === data.senderId);

    return {
      name: found?.name || 'Unknown User',
      avatarUrl: found?.avatarUrl,
      id: data.senderId,
      type: found?.type
    }
  }

  @Action({ rawError: true })
  async sendMessageToCurrentChat(data: { text: string; attachments?: any[] }) {
    const response = await Vue.$axios.post('/messages/send', {
      chatId: this.currentChatId,
      sender: this.currentChatRole,
      text: data.text,
      attachments: data.attachments
    });

    return response.data;
  }

  @Action({ rawError: true })
  async sendMessageToTeacher(data: { teacherId: string; classId?: string; text: string; attachments?: any[] }) {
    const response = await Vue.$axios.post('/messages/send', {
      sender: 'student',
      ...data
    });

    return response.data;
  }

  @Action({ rawError: true })
  async sendMessageToStudent(data: { classId?: string; studentId: string; text: string; attachments?: any[] }) {
    const response = await Vue.$axios.post('/messages/send', {
      sender: 'teacher',
      ...data
    });

    return response.data;
  }

  @Action({ rawError: true })
  async getMessages(data?: { after?: Date; before?: Date }) {
    const searchParams = new URLSearchParams();

    if (data?.before) {
      searchParams.append('before', data.before.toISOString());
    }

    if (data?.after) {
      searchParams.append('after', data.after?.toISOString());
    }
    
    const response = await Vue.$axios.get(`/chats/${this.currentChatId}/messages?${searchParams.toString()}`);

    return {
      items: response.data.items,
      count: response.data.count as number,
    }
  }
}

export default Chat;
