import { getModule } from 'vuex-module-decorators';

import store from './store';

import auth from './auth';
import teachers from './teachers';
import rates from './rates';
import chat from './chat';
import classes from './classes';
import blog from './blog';

export const Auth = getModule(auth, store);
export const Teachers = getModule(teachers, store);
export const Rates = getModule(rates, store);
export const Chat = getModule(chat, store);
export const Classes = getModule(classes, store);
export const Blog = getModule(blog, store);