
import Vue from 'vue';
import Component from 'vue-class-component';
import { Auth } from '@/store/modules';
import { User } from '@/store/auth';
import Cookies from '@/components/Cookies.vue';

@Component({
  name: "Login",
  components: { Cookies },
})
export default class extends Vue {
  model: any = {};
  termsAccepted = false;

  // acceptedTermsConditions: boolean = Auth.termsConditions || false;
  acceptedTermsConditions = false;

  get isOnChatPage() {
    return this.$route.path === '/messages';
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get user() {
    return Auth.user as User;
  }

  get isTeacher() {
    if (!this.loggedIn) {
      return false;
    }

    return this.user.role === 'teacher' || this.user.role === 'admin';
  }

  get footerPadding() {
    if (!(this.$route.meta && this.$route.meta.noFooterPadding)) {
      return ['mt-6', 'mt-sm-12'];
    }

    return [];
  }

  get headerPadding() {
    if (!(this.$route.meta && this.$route.meta.noHeaderPadding)) {
      return ['pt-3', 'pt-md-12'];
    }

    return [];
  }

  get currentYear() {
    return new Date().getFullYear()
  }
}
