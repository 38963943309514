import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import './plugins/axios';
import VueCarousel from 'vue-carousel';
import moment from 'moment';
import Breadcrumbs from './components/Breadcrumbs.vue';
import 'currency-flags/dist/currency-flags.min.css';
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'
import Cookies from './components/Cookies.vue'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  bootstrap: false
}, router);

Vue.use(VueCarousel);
Vue.use(VueMeta, { 
  refreshOnceOnNavigation: true 
});
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('Cookies', Cookies);

Vue.filter('formatDate', (date: any) => {
  if (!date) {
    return 'No date';
  }

  return `${moment(date).format('MMM Do, YYYY')}`;
});

Vue.filter('formatTime', (date: any) => {
  if (!date) {
    return 'No date';
  }

  return `${moment(date).format('h:mma')} ${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}`;
});

Vue.filter('formatDateTime', (date: any) => {
  if (!date) {
    return 'No date';
  }

  return `${moment(date).format('MMM Do, YYYY')} ${moment(date).format('h:mma')} ${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}`;
});

Vue.filter('formatMeetingDate', (meeting: any) => {
  const date = meeting && meeting.date;

  if (!date) {
    return 'No date';
  }

  const end = moment(date).add(meeting.length, "minutes");

  return `${moment(date).format('MMM Do, YYYY')} ${moment(date).format('h:mma')} - ${moment(end).format('h:mma')} ${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}`;
});

Vue.filter('formatLength', (length: number) => {
  if (!length) {
    return 'Invalid length';
  }

  const hours = Math.floor(length / 60);
  const minutes = length - hours * 60;

  return hours ? `${hours}h ${minutes}min` : `${minutes}min`;
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
