import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type SortOptions = 'Date' | 'Price: Low to High' | 'Price: High to Low' | 'Avg. Teacher Ratings';

@Module({ namespaced: true, name: 'classes', preserveState: true})
class Classes extends VuexModule {
  sortBy: SortOptions = 'Date';

  @Mutation
  setSortBy(data: SortOptions) {
    this.sortBy = data;
  }

  @Action
  updateSearchParams(data: { sortBy?: SortOptions }){
    if(data.sortBy !== undefined) {
      this.setSortBy(data.sortBy)
    }
  }
}

export default Classes;
