
import Vue from 'vue';
import Component from 'vue-class-component';
import Cookies from '@/components/Cookies.vue';

@Component({
  components: { Cookies },
  name: 'Layout',
})
export default class extends Vue {
  get containerClass() {
    if (this.$route.name === 'login') {
      return ['login'];
    }

    if (this.$route.name === 'register') {
      return ['register'];
    }

    if (this.$route.name === 'stripe-setup') {
      return ['stripe'];
    }

    if (this.$route.name === 'meeting') {
      return ['nopadding'];
    }

    return [];
  }
}
