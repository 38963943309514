import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { Auth } from '@/store/modules';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home',
      public: true,
      noFooterPadding: true,
      noHeaderPadding: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      title: 'My Account',
    }
  },
  {
    path: '/public-account',
    name: 'public-account',
    component: () => import(/* webpackChunkName: "public-account" */ '../views/PublicAccount.vue'),
    meta: {
      title: 'My Educator Account',
    }
  },
  {
    path: '/payout-profile',
    name: 'payout-profile',
    component: () => import(/* webpackChunkName: "payout-profile" */ '../views/PayoutInfo.vue'),
    meta: {
      title: 'Payout Profile',
      noHeaderPadding: true
    }
  },
  {
    path: '/stripe-callback',
    name: 'stripe-callback',
    component: () => import(/* webpackChunkName: "stripe-callback" */ '../views/StripeCallback.vue'),
    meta: {
      layout: 'centered',
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      title: 'My Profile',
      noHeaderPadding: true
    }
  },
  {
    path: '/start-educating',
    name: 'start-educating',
    component: () => import(/* webpackChunkName: "start-educating" */ '../views/StartEducating.vue'),
    meta: {
      title: 'Start Educating',
      layout: 'centered',
    }
  },
  {
    path: '/create-class',
    name: 'create-class',
    component: () => import(/* webpackChunkName: "create-class" */ '../views/CreateClass.vue'),
    meta: {
      title: 'Create a Class',
      noHeaderPadding: true
    }
  },
  {
    path: '/edit-class/:slug',
    name: 'edit-class',
    component: () => import(/* webpackChunkName: "edit-class" */ '../views/CreateClass.vue'),
    meta: {
      title: 'Edit Class',
      noHeaderPadding: true
    }
  },
  {
    path: '/classes/:slug',
    name: 'class',
    component: () => import(/* webpackChunkName: "class" */ '../views/ClassView.vue'),
    meta: {
      title: 'Class Details',
      noHeaderPadding: true,
      public: true
    }
  },

   {
    path: '/classes/shipping-adreess/:slug',
    name: 'shipping-adreess',
    component: () => import(/* webpackChunkName: "class" */ '../views/ShippingAdreess.vue'),
    meta: {
      title: 'Order Shipping Adreess',
      noHeaderPadding: true
    }
  },
  {
    path: '/reservation-confirmation',
    name: 'reservation-confirmation',
    component: () => import(/* webpackChunkName: "reservation-confirmation" */ '../views/ReservationConfirmation.vue'),
    meta: {
      title: 'Confirmation',
      layout: 'centered'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Categories.vue'),
    meta: {
      title: 'Search',
      noHeaderPadding: true,
      public: true
    }
  },
  // {
  //   path: '/search-results',
  //   name: 'search-results',
  //   component: () => import(/* webpackChunkName: "search-results" */ '../views/Search.vue'),
  //   meta: {
  //     title: 'Search results',
  //     noHeaderPadding: true,
  //     public: true
  //   }
  // },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue'),
    meta: {
      title: 'Payments',
      noHeaderPadding: true
    }
  },
  {
    path: '/teachers/:slug',
    name: 'teacher',
    component: () => import(/* webpackChunkName: "teacher" */ '../views/TeacherView.vue'),
    meta: {
      title: 'Teacher Details',
      noHeaderPadding: true,
      public: true,
    }
  },
  {
    path: '/meeting/:id',
    name: 'meeting',
    component: () => import(/* webpackChunkName: "meeting" */ '../views/Meeting.vue'),
    meta: {
      title: 'Meeting',
      layout: 'centered',
    }
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding.vue'),
    meta: {
      title: 'Onboarding',
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue'),
    meta: {
      title: 'Categories',
      public: true,
      noHeaderPadding: true
    }
  },
  {
    path: '/categories/:category',
    name: 'category',
    component: () => import(/* webpackChunkName: "category" */ '../views/Category.vue'),
    meta: {
      title: 'Category',
      public: true,
      noHeaderPadding: true
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
    meta: {
      title: 'Blog',
      public: true,
      noHeaderPadding: true
    }
  },
  {
    path: '/blog/:slug',
    name: 'post',
    component: () => import(/* webpackChunkName: "post" */ '../views/BlogPost.vue'),
    meta: {
      title: 'Post',
      public: true,
      noHeaderPadding: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Login',
      public: true,
      layout: 'centered'
    }
  },
    {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Login',
      public: true,
      layout: 'centered'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      title: 'Register',
      public: true,
      layout: 'centered'
    }
  },
  {
    path: '/logout-confirmation',
    name: 'logout-confirmation',
    component: () => import(/* webpackChunkName: "logout-confirmation" */ '../views/LogoutCallback.vue'),
    meta: {
      title: 'Logged out',
      layout: 'centered',
      public: true,
    }
  },
  {
    path: '/login-callback',
    name: 'login-callback',
    component: () => import(/* webpackChunkName: "loginCallback" */ '../views/LoginCallback.vue'),
    meta: {
      title: 'Login',
      layout: 'centered',
      public: true,
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      title: 'FAQ',
      public: true,
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'Terms of service',
      public: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy',
      public: true
    }
  },
  {
    path: '/coppa',
    name: 'coppa',
    component: () => import(/* webpackChunkName: "coppa" */ '../components/Coppa.vue'),
    meta: {
      title: 'COPPA Privacy Policy',
      public: true
    }
  },
  {
    path: '/gpdr-policy',
    name: 'gpdr-policy',
    component: () => import(/* webpackChunkName: "gpdr" */ '../components/GpdrPolicy.vue'),
    meta: {
      title: 'GPDR Policy',
      public: true
    }
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import(/* webpackChunkName: "gpdr" */ '../components/CookiePolicy.vue'),
    meta: {
      title: 'Cookie Policy',
      public: true
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messaging.vue'),
    meta: {
      title: 'Messages',
      noHeaderPadding: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
})

router.beforeEach((to, from, next) => {
  const authRequired = !(to.meta && to.meta.public);
  const loggedIn = Auth.loggedIn;
  const termsConditions = Auth.termsConditions;

  if (navigator.userAgent.includes('Instagram') && !navigator.userAgent.includes('iOS')) {
    window.location.href = `${process.env.VUE_APP_API_URL}/instagram?redirect=${to.fullPath}`;
    setTimeout(() => window.close(), 2000);
  }

  if (authRequired && !loggedIn) {
    return next({ path: '/login', query: { redirectUrl: to.fullPath } });
  }

  if (!termsConditions && to.name !== 'account' && loggedIn) {
    return next({ path: '/account', query: { redirectUrl: to.fullPath } });
  }

  if ((to.name === 'login' || to.name === 'register') && loggedIn) {
    return next('/');
  }

  if( (to.name === 'categories')) {
    return next({ name: 'search' });
  }

  const title = (to.meta && to.meta.title);
  document.title = title ? `${title} | SkillToon` : 'SkillToon';

  next();
});


export default router
