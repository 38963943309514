import Vue from 'vue';
import axios from 'axios';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Teacher } from './teachers';

import randomstring from 'randomstring';
import crypto from 'crypto-js';
import sha256 from 'crypto-js/sha256';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000';
const AUTH_CLIENTID = process.env.VUE_APP_AUTH_CLIENTID;
const AUTH_DOMAIN = process.env.VUE_APP_AUTH_DOMAIN;
const APP_URL = process.env.VUE_APP_APP_URL;

export type UserRole = 'student' | 'teacher' | 'admin';

export type UserDetails = {
  social_account?: {
    name: string;
    avatarUrl?: string;
  };
  youtube?: string;
  instagram?: string;
  facebook?: string;
  twitter?: string;
  bio?: string;
  monthsTeaching?: number;
  numberOfClasses?: number;
  termsConditions?: boolean;
  privacyPolicy?: boolean;
  marketing?: boolean;
  onboardingFinished?: boolean;
  tutorialFinishedCounter?: number;
}

export interface User {
  // identification data
  id: string;
  uid?: string;
  email: string;

  // password used only for admins
  password?: string;
  tokenId?: string;
  
  // social
  name: string;
  avatarUrl?: string;
  details: UserDetails;

  role: UserRole;
  registeredAt: Date;
  lastActivityDate: Date;
  deletedAt?: Date;
  disabledAt?: Date;

  hasPaidClasses?: boolean;

  teacherId?: string;
  teacher?: Teacher;

  updatedAt: Date;
  createdAt: Date;
}

@Module({ namespaced: true, name: 'auth', preserveState: true  })
class Auth extends VuexModule {
  user: User | {} = {};
  error = '';
  token = '';
  refreshToken = '';
  loading = false;
  meetingEndDate: null | Date = null;

  @Mutation
  setUser(payload: User) {
    this.user = Object.assign({}, payload);
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setToken(payload: string) {
    this.token = payload;
  }

  @Mutation
  setRefreshToken(payload: string) {
    this.refreshToken = payload;
  }

  @Mutation
  removeToken() {
    this.token = '';
    this.refreshToken = '';
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.user = {};
    this.error = '';
    this.token = '';
    this.refreshToken = '';
    this.loading = false;
    this.meetingEndDate = null;
  }

  @Mutation
  setMeetingEndDate(payload: Date | null) {
    this.meetingEndDate = payload;
  }

  get loggedIn() {
    return !!this.token;
  }

  get finishedTeacherOnboarding() {
    return this.user && (this.user as User).role === 'teacher' && ((this.user as User).teacher as Teacher) && ((this.user as User).teacher as Teacher).about
  }

  get termsConditions() {
    return !this.loggedIn ? false : (this.user as User).details?.termsConditions;
  }

  @Action({ rawError: true })
  async googleSignUp(data?: { redirectUrl?: string }) {
    this.oauthLogin({ provider: 'Google', ...data });
  }

  @Action({ rawError: true })
  async facebookSignUp(data?: { redirectUrl?: string }) {
    this.oauthLogin({ provider: 'Facebook', ...data });
  }

  @Action({ rawError: true })
  async getSelfInformation() {
    const timezoneOffset = new Date().getTimezoneOffset();
    const response = await Vue.$axios.get(`${API_URL}/auth/me?tz=${timezoneOffset}`);
    const responseData = response.data;
    this.setUser(responseData);
    return this.user;
  }

  @Action({ rawError: true })
  async doLogout(data?: { redirectUrl?: string; authError?: boolean; errorCode?: string; noRedirect?: boolean }) {
    try {
      await axios.get(`${API_URL}/auth/remove-token`, { headers: { Authorization: `Bearer ${this.token}` }});
    } catch (err) {
      console.log('User was already logged out');
    }

    this.resetState();

    if (data && (data.redirectUrl || data.authError || data.errorCode)) {
      const queryParams = new URLSearchParams();

      if (data.redirectUrl) {
        queryParams.append('redirectUrl', data.redirectUrl);
      }

      if (data.authError) {
        queryParams.append('authError', 'true');
      }

      if (data.errorCode) {
        queryParams.append('errorCode', data.errorCode);
      }

      window.location.href = `/login?${queryParams}`;
      return;
    }

    if (data && data.noRedirect) {
      return;
    }
    
    if (this.refreshToken) {
      window.location.href = `${AUTH_DOMAIN}/logout?client_id=${AUTH_CLIENTID}&logout_uri=${APP_URL}/logout`;
    } else {
      window.location.href = `/login`;
    }
  }

  @Action({ rawError: true })
  async updateAccount(data: Partial<User>) {
    await Vue.$axios.patch('/account', data);
    return this.getSelfInformation(); 
  }

  @Action({ rawError: true })
  async getUploadUrl(data: { type: 'user_avatar' | 'teacher_avatar' | 'class_image' | 'attachment'; id: string }) {
    const response = await Vue.$axios.get(`/upload?type=${data.type}&id=${data.id}`);
    return response.data.url;
  }

  @Action({ rawError: true })
  async oauthLogin(payload: { redirectUrl?: string; provider: 'Google' | 'Facebook' }) {
    const codeVerifier = randomstring.generate(128);
    const rawLoginState = {
      code: randomstring.generate(64),
      redirectUrl: payload.redirectUrl,
    };
    const encodedLoginState = crypto.enc.Utf8.parse(JSON.stringify(rawLoginState));
    const loginState = crypto.enc.Base64.stringify(encodedLoginState);
    localStorage.setItem('codeVerifier', codeVerifier);
    localStorage.setItem('loginState', loginState);
    const codeChallenge = sha256(codeVerifier).toString(crypto.enc.Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    window.location.href = `${AUTH_DOMAIN}/oauth2/authorize?response_type=code&client_id=${AUTH_CLIENTID}&redirect_uri=${APP_URL}/login-callback&scope=openid%20email%20profile&code_challenge=${codeChallenge}&code_challenge_method=S256&state=${loginState}&identity_provider=${payload.provider}`;
  }

  @Action({ rawError: true })
  async oauthGetToken(payload: { code: string; state: string }) {
    const { code, state } = payload;
      const loginState = localStorage.getItem('loginState');

      if (loginState !== state) {
        throw new Error("State doesn't match");
      }

      const decryptedLoginState = JSON.parse(crypto.enc.Utf8.stringify(crypto.enc.Base64.parse(state)));

      // eslint-disable-next-line @typescript-eslint/camelcase
      const code_verifier = localStorage.getItem('codeVerifier');

      // eslint-disable-next-line @typescript-eslint/camelcase
      const response = await axios.post(`${API_URL}/auth/oauth2-login`, { code, code_verifier });

      localStorage.removeItem('codeVerifier');
      localStorage.removeItem('loginState');

      // eslint-disable-next-line @typescript-eslint/camelcase
      const { access_token, refresh_token, user } = response.data;

      // eslint-disable-next-line @typescript-eslint/camelcase
      if (!access_token || !refresh_token || !user) {
        throw new Error('Got invalid response from backend.');
      }

      this.setToken(access_token);
      this.setRefreshToken(refresh_token);
      localStorage.setItem('loggedIn', 'true')
      await this.getSelfInformation();

      const upcomingToday = (await Vue.$axios.get('/classes/upcoming?today=true')).data.items;

      if (upcomingToday.length === 0) {
        window.location.href = decryptedLoginState.redirectUrl || '/categories';
      } else {
        if (upcomingToday[0].teacherId === user.teacherId) {
          window.location.href = decryptedLoginState.redirectUrl || '/profile?tab=teacher';
        } else {
          window.location.href = decryptedLoginState.redirectUrl || '/profile';
        }
      }
  }

  @Action({ rawError: true })
  openLogoutPage() {
    localStorage.removeItem('loggedIn')
    window.location.href = `${AUTH_DOMAIN}/logout?client_id=${AUTH_CLIENTID}&logout_uri=${APP_URL}/logout-confirmation`;
  }

  @Action({ rawError: true })
  async resetStateAction() {
    this.resetState();
  }
}

export default Auth;
